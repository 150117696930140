@import "../../styles/App.scss";

.access-wrapper {
  .card-header{
    background:#F9F6FA;
  }
  &__plan {
    flex: 2 2 32%;
    &__list-item {
      min-width: 250px;
      width: 50%;
      flex-grow: 1;
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
    @include media-breakpoint-down(md) {
      width: fit-content;
      &__list-item {
        width: initial;
      }
    }
    .card {
      &:hover {
        border-color: $purple-500;
      }
    }
    &--active {
      .card {
        border: 2px solid $primary;
      }
    }
  }
}
