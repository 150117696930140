@import "../styles/customBootstrap/custom-variables";

$sidebar-width: 200px;
$header-height: 75px;

header.navbar {
  height: $header-height;
  padding: 0 0 0 $sidebar-width;
  border-bottom-color: rgba($indigo-300, 50%);
  svg {
    fill: $primary;
  }
}

/*
 * Sidebar
 */
.sidebar, #sidebarMenu {
  width: $sidebar-width;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1021; /* Before the navbar */
  &-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - $header-height);
    padding-top: 0.5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  }
  &-heading {
    font-size: 0.75rem;
    text-transform: uppercase;
  }
  .navbar-brand {
    height: $header-height;
    border-bottom-color: rgba($indigo-300, 0.5) !important;
  }
  ~ main {
    max-width: calc(100% - $sidebar-width);
    min-height: calc(100vh - $header-height);
    background-color: $purple-100;
  }
  .nav-link {
    svg {
      opacity: 0.7;
    }
    &:hover {
      svg {
        opacity: 0.9;
      }
    }
    &.active {
      svg {
        opacity: 1;
      }
    }
  }
}

/*
 * Navbar
 */
.navbar {
  > a {
    border-bottom-color: rgba($indigo-300, 0.5) !important;
  }
  > div {
    border-bottom-color: rgba($purple-300, 0.5) !important;
  }
  .dropdown-toggle {
    cursor: pointer;
    &::after {
      display: none;
    }
  }
}

@media (max-width: 767.98px) {
  header.navbar {
    padding: 0;
  }
  .sidebar {
    top: 5rem;
    bottom: initial;
    width: 100%;
    padding: 0;
    ~ main,
    > div {
      width: 100%;
      max-width: initial;
    }
  }
}
