@import "../../../styles/customBootstrap/custom-variables";

.credit-slider {
  $track-height: 6px;

  text-align: center;
  position: relative;

  @mixin _track {
    content: "";
    position: absolute;
    top: $track-height;
    left: 0;
    width: 100%;
    border-radius: 999px;
    height: $track-height;
  }
  @mixin _thumb {
    -webkit-appearance: none;
    appearance: none;
    top: 0;
    width: $track-height * 3;
    height: $track-height * 3;
    background-color: $secondary;
    border: 2px solid $white;
    border-radius: 999px;
    margin-top: -($track-height - 2);
  }
  @mixin _thumb--focus {
    outline: 1px solid $secondary;
  }
  @mixin _central-absolute {
    content: "";
    position: absolute;
    transform: translateX(-50%);
  }

  &__fill-bar,
  &::before {
    @include _track;
  }
  &::before {
    background-color: $purple-300;
  }
  &__fill-bar {
    background-image: linear-gradient(90deg, $primary 0%, $secondary 100%);
    background-repeat: no-repeat;
    &__credit-wrap {
      position: absolute;
      transform: translate(-50%, -50%);
      &__price {
        position: absolute;
        bottom: 16px;
        transform: translateX(-50%);
        padding: 5px;
        color: $primary;
        font-weight: 600;
        font-size: map-get($font-sizes, 7);
        background-color: $purple-300;
        border-radius: 3px;
        &::before {
          content: "";
          position: absolute;
          z-index: -1;
          bottom: -7px;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-color: currentColor;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-top: 14px solid $purple-300;
        }
      }
      &__storage {
        position: absolute;
        bottom: -120px;
        left: 0;
        transform: translateX(-50%);
        color: $white;
        background: linear-gradient(133.32deg, $primary -45.97%, $secondary 64.3%);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        padding: 10px 9px;
        min-width: 120px;
        text-align: initial;
        small {
          color: $purple-300;
          font-size: map-get($font-sizes, 8);
          font-weight: 500;
        }
        &::before {
          content: "";
          position: absolute;
          top: -45px;
          left: 50%;
          height: 40px;
          transform: translateX(-50%);
          background-color: #9889a4;
          border: 1px solid #9889a4;
          border-bottom-left-radius: 999px;
          border-bottom-right-radius: 999px;
        }
        $transform-edge: 33px;
        &.left-edge {
          left: $transform-edge;
          &::before {
            left: calc(50% - $transform-edge);
          }
        }
        &.right-edge {
          left: -$transform-edge;
          &::before {
            left: calc(50% + $transform-edge);
          }
        }
      }
    }
  }

  input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    z-index: 10;
    background-color: transparent;
    height: $track-height * 3;
    cursor: pointer;
    outline: none;

    &:focus {
      &::-webkit-slider-thumb {
        @include _thumb--focus;
      }
      &::-moz-range-thumb {
        @include _thumb--focus;
      }
    }

    // &::-webkit-slider-runnable-track {
    // }
    // &::-moz-range-track {
    // }

    &::-webkit-slider-thumb {
      @include _thumb;
    }
    &::-moz-range-thumb {
      @include _thumb;
    }

    &::-ms-fill-lower {
      background-color: $secondary;
    }
    &::-moz-range-progress {
      background-color: $secondary;
    }
  }

  datalist {
    display: flex;
    flex: 1 1 auto;
    option {
      display: flex;
      flex-direction: column;
      &:before {
        content: "";
        align-self: center;
        height: 10px;
        border: 1px solid $purple-300;
      }
    }
  }
}
