// Color system

// scss-docs-start gray-color-variables
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;
// scss-docs-end gray-color-variables

// scss-docs-start color-variables
// $blue:    #0d6efd !default;
$indigo: #6610f2;
$purple: #421e5f;
// $pink:    #d63384 !default;
$red: #ea1a4c;
// $orange:  #fd7e14 !default;
$yellow: #c2c62c !default;
$green: #589e4d;
$teal: #79d76a;
// $cyan:    #0dcaf0 !default;
// scss-docs-end color-variables
// scss-docs-start theme-color-variables
$primary: #421e5f;
$secondary: #9747ff;
$success: $green;
// $info:          $cyan;
// $warning:       $yellow;
// $danger:        $red;
$light: #8a7997;
// $dark:          $gray-900;

// $indigo-100: tint-color($indigo, 80%);
// $indigo-200: tint-color($indigo, 60%);
$indigo-300: #b073df;
$indigo-400: #9747ff;
$indigo-500: $indigo;
// $indigo-600: shade-color($indigo, 20%);
// $indigo-700: shade-color($indigo, 40%);
// $indigo-800: shade-color($indigo, 60%);
// $indigo-900: shade-color($indigo, 80%);

$purple-100: #f3eef6;
$purple-200: #eed8ff;
$purple-300: #e1ccf1;
$purple-400: #9889a4;
$purple-500: #8a7997;
$purple-600: tint-color($purple, 20%);
$purple-700: $purple;
$purple-800: shade-color($purple, 20%);
$purple-900: shade-color($purple, 40%);

// scss-docs-end theme-color-variables

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true;
$enable-rounded: true;
$enable-shadows: false;

// scss-docs-start spacer-variables-maps
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.25,
  5: $spacer * 2.5,
  6: $spacer * 3,
);
// scss-docs-end spacer-variables-maps

// scss-docs-start border-variables
$border-width: 1px;
$border-color: $purple-300;
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius: 0.375rem;
$border-radius-sm: 0.25rem;
$border-radius-lg: 0.5rem;
$border-radius-xl: 1rem;
$border-radius-2xl: 2rem;
$border-radius-pill: 50rem;
// scss-docs-end border-radius-variables

// scss-docs-start box-shadow-variables
$box-shadow: 0 0.5rem 1rem rgba($primary, 0.15);
$box-shadow-sm: 0 0.25rem 0.5rem rgba($primary, 0.1);
$box-shadow-lg: 0 1rem 3rem rgba($primary, 0.175);
$box-shadow-inset: inset 0 1px 2px rgba($primary, 0.075);
// scss-docs-end box-shadow-variables

$component-active-color: $white;
$component-active-bg: $secondary;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-sans-serif: "Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans",
  "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root: null;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.25;
$small-font-size: 0.75rem;

$line-height-base: 1;
$line-height-sm: 0.75;
$line-height-lg: 1.375;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6-lg: $font-size-base * 1.125,
  6: $h6-font-size,
  7: $font-size-base * 0.875,
  8: $font-size-base * 0.75,
);

// scss-docs-end font-variables

// scss-docs-start type-variables
$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: 300;

$small-font-size: 0.875em;

$sub-sup-font-size: 0.75em;

$text-muted: #8a7997;
// scss-docs-end type-variables

// Links
//
// Style anchor elements.

$link-decoration: initial;
$link-shade-percentage: 20%;
$link-hover-decoration: underline;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-cell-padding-y: 0.188rem;
$table-cell-padding-x: 1.25rem;
$table-cell-padding-y-sm: 0.5rem;
$table-cell-padding-x-sm: 0.25rem;

$table-cell-vertical-align: center;

// $table-color:                 var(--#{$prefix}body-color) !default;
$table-bg: transparent;
$table-accent-bg: transparent;

$table-th-font-weight: 500;

// $table-striped-color:         $table-color !default;
// $table-striped-bg-factor:     .05 !default;
// $table-striped-bg:            rgba($black, $table-striped-bg-factor) !default;

// $table-active-color:          $table-color !default;
// $table-active-bg-factor:      .1 !default;
// $table-active-bg:             rgba($black, $table-active-bg-factor) !default;

// $table-hover-color:           $table-color !default;
$table-hover-bg-factor: 0.75;
$table-hover-bg: #f9f5fc;

$table-border-factor: 0.1;
$table-border-width: $border-width;
$table-border-color: #e1ccf1;

$table-striped-order: odd;
$table-striped-columns-order: even;

$table-group-separator-color: currentcolor;

$table-caption-color: $text-muted;

$table-bg-scale: -80%;
// scss-docs-end table-variables

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-x: 0;
// scss-docs-end input-btn-variables

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-padding-y: 0.75rem; // 12px
$btn-padding-x: 0.875rem; // 14px
$btn-font-size: normal;

$btn-line-height: 1;
$btn-font-weight: 600;
$btn-disabled-opacity: 0.6;

$btn-padding-y-sm: 0.438rem; // 7px
$btn-padding-x-sm: 0.625rem; // 10px
$btn-font-size-sm: 0.875rem;

$btn-border-radius: $border-radius;
$btn-border-radius-sm: $border-radius;
$btn-border-radius-lg: $border-radius-lg;
// scss-docs-end btn-variables

// Forms

// scss-docs-start form-text-variables
// scss-docs-end form-text-variables

// scss-docs-start form-label-variables
$form-label-margin-bottom: 0;
$form-label-font-weight: 500;
$form-label-color: #9889a4;
// scss-docs-end form-label-variables

// scss-docs-start form-input-variables
// $input-padding-y:                       $input-btn-padding-y !default;
$input-padding-x: $input-btn-padding-x;
// $input-font-family:                     $input-btn-font-family !default;
// $input-font-size:                       $input-btn-font-size !default;
$input-font-weight: 500;
// $input-line-height:                     $input-btn-line-height !default;

// $input-padding-y-sm:                    $input-btn-padding-y-sm !default;
// $input-padding-x-sm:                    $input-btn-padding-x-sm !default;
// $input-font-size-sm:                    $input-btn-font-size-sm !default;

// $input-padding-y-lg:                    $input-btn-padding-y-lg !default;
// $input-padding-x-lg:                    $input-btn-padding-x-lg !default;
// $input-font-size-lg:                    $input-btn-font-size-lg !default;

// $input-bg:                              $body-bg;
// $input-disabled-color:                  null !default;
// $input-disabled-bg:                     $gray-200 !default;
// $input-disabled-border-color:           null !default;

$input-color: $primary;
$input-border-color: $purple-400;
// $input-border-width:                    $input-btn-border-width;
// $input-box-shadow:                      $box-shadow-inset !default;

$input-border-radius: 0;
$input-border-radius-sm: 0;
$input-border-radius-lg: 0;

$input-focus-bg: $purple-100;
$input-focus-border-color: tint-color($component-active-bg, 50%) !default;
// $input-focus-color:                     $input-color !default;
$input-focus-width: 0;
$input-focus-box-shadow: none;

$input-placeholder-color: $text-muted;
// $input-plaintext-color:                 $body-color !default;

// $input-height-border:                   $input-border-width * 2 !default;
$form-color-width: 3rem;
// scss-docs-end form-input-variables

// Form validation

// scss-docs-start form-feedback-variables
$form-feedback-invalid-color: #ea1a4c;

$form-feedback-icon-valid: initial;
$form-feedback-icon-invalid: initial;
// $form-feedback-icon-invalid:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>") !default;
// scss-docs-end form-feedback-variables

// scss-docs-start form-check-variables
$form-check-input-width: 0.938em;
$form-check-input-border: 1px solid #8a7997;
$form-check-input-border-radius: 0.188em;
$form-check-input-checked-bg-color: $component-active-bg;
$form-check-input-checked-border-color: $component-active-bg;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 15 15' fill='none'><path d='M13.3333 0H1.66667C0.741667 0 0 0.75 0 1.66667V13.3333C0 14.25 0.741667 15 1.66667 15H13.3333C14.2583 15 15 14.25 15 13.3333V1.66667C15 0.75 14.2583 0 13.3333 0ZM5.83333 11.6667L1.66667 7.5L2.84167 6.325L5.83333 9.30833L12.1583 2.98333L13.3333 4.16667L5.83333 11.6667Z' fill='#{$component-active-bg}'/></svg>");

// scss-docs-end form-check-variables

// scss-docs-start form-switch-variables
$form-switch-color: rgba($black, 0.25);
$form-switch-checked-color: $component-active-color;
$form-switch-width: 2.188em;
$form-switch-padding-start: $form-switch-width + 0.5em;
$form-switch-bg-color: #8a7997;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='4' fill='#{$form-switch-checked-color}'/></svg>");
$form-switch-border-radius: $form-switch-width;
$form-switch-transition: background-position 0.15s ease-in-out;

$form-switch-focus-color: $input-focus-border-color;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='4' fill='#{$form-switch-focus-color}'/></svg>");

$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='4' fill='#{$form-switch-checked-color}'/></svg>");
$form-switch-checked-bg-position: right center;
// scss-docs-end form-switch-variables

// Carousel

// scss-docs-start carousel-variables
$carousel-control-color: #ffffff;
$carousel-control-width: 12%;
$carousel-control-opacity: 1;
$carousel-control-hover-opacity: 0.55;

$carousel-control-icon-width: 1rem;

$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 17' fill='none'><path d='M8.09717 -1.66351e-07L10 1.90283L3.81916 8.09717L10 14.2915L8.09716 16.1943L-7.07877e-07 8.09717L8.09717 -1.66351e-07Z' fill='#{$carousel-control-color}'/></svg>");
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 17' fill='none'><path d='M1.90283 17L0 15.0972L6.18084 8.90283L0 2.7085L1.90283 0.805667L10 8.90283L1.90283 17Z' fill='#{$carousel-control-color}'/></svg>");

// Spinners

// scss-docs-start spinner-variables
$spinner-vertical-align: text-top;
$spinner-width-sm: 0.938rem;
$spinner-height-sm: $spinner-width-sm;
$spinner-border-width-sm: 0.125rem;
// scss-docs-end spinner-variables

// scss-docs-start form-select-variables
$form-select-font-weight: 500;
$form-select-color: #421e5f;
$form-select-bg-size: 13px 8px; // In pixels because image dimensions
$form-select-indicator-color: #8a7997;
$form-select-indicator: url('data:image/svg+xml,<svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.705 1.70498L11.295 0.294983L6.70496 4.87498L2.11496 0.294982L0.704956 1.70498L6.70496 7.70498L12.705 1.70498Z" fill="#{$form-select-indicator-color}"/></svg>');
$form-select-border-color: #8a7997;

// scss-docs-end form-select-variables

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// scss-docs-start zindex-stack
$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-offcanvas-backdrop: 1040 !default;
$zindex-offcanvas: 1045 !default;
$zindex-toast: 1090 !default;
$zindex-modal-backdrop: 1100 !default;
$zindex-modal: 1105 !default;
$zindex-popover: 1110 !default;
$zindex-tooltip: 1115 !default;
// scss-docs-end zindex-stack

// Navs

// scss-docs-start nav-variables
$nav-link-padding-y: map-get($spacers, 2);
$nav-link-padding-x: map-get($spacers, 2);
$nav-link-font-size: $font-size-base * 1.125;
$nav-link-font-weight: 500;

$nav-pills-border-radius: $border-radius;
$nav-pills-link-active-color: $white;
$nav-pills-link-active-bg: $indigo-400;
// scss-docs-end nav-variables

// Navbar

// scss-docs-start navbar-variables
$navbar-padding-y: map-get($spacers, 2);
$navbar-padding-x: map-get($spacers, 2);

$navbar-nav-link-padding-x: 0.5rem;

$navbar-brand-font-size: $font-size-base * 1.125;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
// $nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
// $navbar-brand-height:               $navbar-brand-font-size * $line-height-base !default;
// $navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) * .5 !default;
$navbar-brand-margin-end: 1rem;

$navbar-toggler-padding-y: 0.25rem;
$navbar-toggler-padding-x: 0.75rem;
$navbar-toggler-font-size: $font-size-base * 1.125;
$navbar-toggler-border-radius: $border-radius;
// $navbar-toggler-focus-width:        $btn-focus-width !default;
$navbar-toggler-transition: box-shadow 0.15s ease-in-out;

$navbar-light-color: rgba($white, 0.7);
$navbar-light-hover-color: rgba($white, 0.9);
$navbar-light-active-color: rgba($white, 1);
$navbar-light-disabled-color: rgba($white, 0.3);
// $navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
// $navbar-light-toggler-border-color: rgba($black, .1) !default;
// $navbar-light-brand-color:          $navbar-light-active-color !default;
// $navbar-light-brand-hover-color:    $navbar-light-active-color !default;
// scss-docs-end navbar-variables

// Dropdowns
//
// Dropdown menu container and contents.

// scss-docs-start dropdown-variables
$dropdown-border-color: $purple-300;
$dropdown-border-radius: $border-radius;
// scss-docs-end dropdown-variables

// Cards

// scss-docs-start card-variables
$card-spacer-y: $spacer;
$card-spacer-x: $spacer;
$card-title-spacer-y: $spacer * 0.5;
// $card-border-width:                 $border-width !default;
$card-border-color: $purple-300;
$card-border-radius: $border-radius;
$card-box-shadow: 0px 4px 8px rgba(66, 30, 95, 0.1);
// $card-inner-border-radius:          subtract($card-border-radius, $card-border-width) !default;
$card-cap-padding-y: $card-spacer-y * 1.375;
$card-cap-padding-x: $card-spacer-x * 1.25;
$card-cap-bg: $white;
$card-cap-color: $purple;
$card-height: null;
$card-color: $white;
$card-bg: $white;
$card-img-overlay-padding: $spacer;
// $card-group-margin:                 $grid-gutter-width * .5 !default;
// scss-docs-end card-variables

// Popovers

// scss-docs-start popover-variables
$popover-font-size: $font-size-base;
$popover-bg: linear-gradient(103.84deg, $primary -113.54%, $secondary 98.62%);
$popover-max-width: 261px;
$popover-border-width: 0rem;
$popover-border-color: $secondary;
$popover-border-radius: $border-radius;
$popover-inner-border-radius: subtract($popover-border-radius, $popover-border-width) !default;
$popover-box-shadow: $box-shadow-lg;

$popover-header-font-size: $font-size-base !default;
$popover-header-bg: shade-color($secondary, 6%) !default;
$popover-header-color: $white;
$popover-header-padding-y: 0.5rem !default;
$popover-header-padding-x: $spacer !default;

$popover-body-color: $white;
$popover-body-padding-y: 1.25rem;
$popover-body-padding-x: 1.25rem;

$popover-arrow-width: 1rem;
$popover-arrow-height: 1rem;
// scss-docs-end popover-variables

// fusv-disable
// Deprecated in Bootstrap 5.2.0 for CSS variables
$popover-arrow-color: $secondary;
$popover-arrow-outer-color: $secondary;
// fusv-enable

// Modals

// scss-docs-start modal-variables
$modal-inner-padding: map-get($spacers, 4);

$modal-footer-margin-between: 0.5rem;

$modal-dialog-margin: 0.5rem;
$modal-dialog-margin-y-sm-up: 1.75rem;

// $modal-title-line-height:           $line-height-base !default;

// $modal-content-color:               null !default;
// $modal-content-bg:                  $white !default;
$modal-content-border-color: #e1ccf1;
// $modal-content-border-width:        $border-width !default;
$modal-content-border-radius: $border-radius;
// $modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
$modal-content-box-shadow-xs: 0px 0px 20px 8px rgba(0, 0, 0, 0.1);
// $modal-content-box-shadow-sm-up:    $box-shadow !default;

$modal-backdrop-bg: $purple-200;
$modal-backdrop-opacity: 0.4;

$modal-header-border-color: #e1ccf1;
// $modal-header-border-width:         $modal-content-border-width !default;
$modal-header-padding-y: 1.625rem;
$modal-header-padding-x: map-get($spacers, 4);
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility

// $modal-footer-bg:                   null !default;
$modal-footer-border-color: $modal-header-border-color;
// $modal-footer-border-width:         $modal-header-border-width !default;

$modal-sm: 300px;
$modal-md: 600px;
$modal-lg: 800px;
$modal-xl: 1140px;

$modal-fade-transform: translate(0, -50px);
$modal-show-transform: none;
$modal-transition: transform 0.3s ease-out;
$modal-scale-transform: scale(1.02);
// scss-docs-end modal-variables
