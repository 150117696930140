@import "./customBootstrap/custom-bootstrap";
@import "./stripe.scss";

// 5. Add additional custom code here
.fw-500 {
  font-weight: 500;
}
@each $name, $color in $purples {
  .#{$name} {
    background-color: $color;
    color: rgba(255, 255, 255, 0.5);
  }
}
@each $name, $color in $indigos {
  .#{$name} {
    background-color: $color;
  }
}

.normal_p{
  font-size:15px;
  font-weight:400 !important;
}

.overages_pop{
  .popover-arrow:before{
    border-bottom-color: #8a47e5 !important;
  }
  .popover-arrow:after{
    border-bottom-color: #8a47e5 !important;
  }
}