.main-table {
  &__head {
    &__row {
      &__cell {
      }
    }
  }
  &__body {
    &__row {
      &__cell {
        height: 70px;
        vertical-align: middle;
        &.clickable {
          cursor: pointer;
        }
      }
    }
  }
}
