@import "../../styles/customBootstrap/custom-variables";

.billing-wrapper {
  &__btn-status {
    cursor: default;
    &.btn-outline-success {
      background-color: rgba($teal, 0.34) !important;
    }
    &.btn-outline-warning {
      background-color: rgba(#fcff70, 0.34) !important;
    }
    &.btn-outline-danger {
      background-color: rgba($red, 0.34) !important;
    }
  }
  .card_data {
    span {
      color: #421e5f;
    }
    small {
      color: #421e5f;
    }
  }
}
